import './Home.scss';
import React, { Component } from "react";
import HomeHeader from "../../components/HomeHeader/HomeHeader";
import WelcomeScreen from "../../components/welcomeScreen/WelcomeScreen";

export default class Home extends Component {
    render() {
        return(
            <div className="home">
                <HomeHeader />
                <WelcomeScreen />
            </div>
        )
    }
}
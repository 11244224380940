import React, { Component } from "react"
import "./Advantages.scss"
import advantages from  "./advantages.svg"

export default class Advantages extends Component {
    render() {
        return(
            <div className="advantages">
                <h2 className='advantages-header'>Наши преимущества</h2>
                <div className='advantages-container'>
                    <div className="advantages-item">
                        <img src={advantages} className="advantages-item-img"/>
                        <div className="advantages-item-text">Современное оборудование</div>
                    </div>
                    <div className="advantages-item">
                        <img src={advantages} className="advantages-item-img"/>
                        <div className="advantages-item-text">Расположение в центре города</div>
                    </div>
                    <div className="advantages-item">
                        <img src={advantages} className="advantages-item-img"/>
                        <div className="advantages-item-text">Выгодные цены</div>
                    </div>
                </div>
            </div>
        )
    }
}
import React, { Component } from "react"
import "./Benefits.scss"
import check from  "./check.png"

export default class Benefits extends Component {
    render() {
        return(
            <div className="benefits">
                <h2 className='benefits-header'>Польза галотерапии</h2>
                <div className='benefits-container'>
                    <div className="benefits-item">
                        <img src={check} className="benefits-item-img"/>
                        <div className="benefits-item-text">Профилактика ОРВИ и COVID-19</div>
                    </div>
                    <div className="benefits-item">
                        <img src={check} className="benefits-item-img"/>
                        <div className="benefits-item-text">Снятие стресса и релаксация</div>
                    </div>
                    <div className="benefits-item">
                        <img src={check} className="benefits-item-img"/>
                        <div className="benefits-item-text">Противоаллергическое действие</div>
                    </div>
                </div>
            </div>
        )
    }
}
import React,  { useState } from "react"
import "./Subscriptions.scss";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { isCompositeComponent } from "react-dom/test-utils";
import axios from "axios";
import { event } from "jquery";



const marks = [
    {
      value: 0,
      label: 'Разовое',
    },
    {
      value: 25,
      label: '10',
    },
    {
      value: 50,
      label: '15',
    },
    {
      value: 75,
      label: '20',
    },
    {
        value: 100,
        label: 'Безлимит',
      },
  ];

function valuetext(value) {
    return `${value}`;
  }


export default function Subscriptions() {
    const adultArr = [250, 1800, 2500, 3000, 4900];
    const adultDiscountArr = [ '0%', '28%', '34%', '40%', '75%' ];
    const kidArr =[200, 1400, 2000, 2400, 4900];
    const kidDiscountArr = [ '0%', '30%', '34%', '40%', '70%' ];
    const oldArr = [250, 1600, 2200, 2600, 4900];
    const oldDiscountArr = [ '0%', '36%', '42%', '48%', '75%' ];
    const counterArr = [ 0, 25, 50, 75, 100 ];
    const agesArr = ['Взрослый', 'Детский', 'Пенсионный'];
    let buttonRef = React.createRef();
    const [alignment, setAlignment] = React.useState('adult');

    const handleChange = (event, newAlignment) => {
     if (newAlignment !== null) {
            setAlignment(newAlignment);
          }
      
    };
    const [costOutputValue, setCostOutputValue] = useState(adultArr[0]);
    const [discountOutputValue, setDiscountOutputValue] = useState(adultDiscountArr[0]);
    const [ageValue, setAgeValue] = useState("adult");
    
    function clickButton(event){
        const a = event.target.value;
        setAgeValue(a);
        for (let i = 0; i < counterArr.length; i++) {
          if (a === 'adult'){
              if(costOutputValue === oldArr[i] || costOutputValue === kidArr[i] || costOutputValue === adultArr[i]){
                setCostOutputValue(adultArr[i]);
                setDiscountOutputValue(adultDiscountArr[i]);
                break;
              } 
          }
          if (a === 'kid'){
            if(costOutputValue === oldArr[i] || costOutputValue === adultArr[i] || costOutputValue === kidArr[i]){
              setCostOutputValue(kidArr[i]);
              setDiscountOutputValue(kidDiscountArr[i]);
              break;
            }
          }
          if (a === 'old'){
            if(costOutputValue === kidArr[i] || costOutputValue === adultArr[i] || costOutputValue === oldArr[i]){
              setCostOutputValue(oldArr[i]);
              setDiscountOutputValue(oldDiscountArr[i]);
              break;
            }
          }
          console.log(a, costOutputValue)

      }
    }

    function showInput(event) {
      let abonementValue = event.target.value;
      for (let i = 0; i < counterArr.length; i++) {
        if (ageValue === 'adult'){
          if (abonementValue === counterArr[i]){
              setCostOutputValue(adultArr[i]);
              setDiscountOutputValue(adultDiscountArr[i])
          }
        }
        if (ageValue === 'kid'){
          if (abonementValue === counterArr[i]){
              setCostOutputValue(kidArr[i]);
              setDiscountOutputValue(kidDiscountArr[i])
          }
        }
        if (ageValue === 'old'){
          if (abonementValue === counterArr[i]){
              setCostOutputValue(oldArr[i]);
              setDiscountOutputValue(oldDiscountArr[i])
          }
        }
      }
    }
  
 //функции модального окна
        const [post, setPost] = useState({
          name: '',
          tel: ''
        })
        const [showModal, setShowModal] = useState(false);
        const handleInput = (event) => {
          setPost({...post, [event.target.name]: event.target.value})
        }


        function handleSubmit(event) {
          event.preventDefault();
          console.log(post)
          axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: 'https://academsalt.ru/form.php',
            data: { post }
        })
        .then(function(response) {
        
            console.log(response);
        
        })
        .catch(function (error) {
            console.log(error);
        });
          setShowModal(false);
          //setName("");
          //setPhoneNumber("");
        }

        function handleClose() {
          setShowModal(false);
          //setName("");
          //setPhoneNumber("");
        }   
        return(
            <div className="subscriptions">
                <h2 className="subscriptions-header">Рассчитайте свой абонемент</h2>
                <div className="subscription-container">
                  <div className="slider-buttons-container">
                    <Box className="slider-container">
                      <Slider
                          aria-label="Custom marks"
                          defaultValue={0}
                          getAriaValueText={valuetext}
                          step={25}
                          marks={marks}
                          onChange={showInput}
                      />
                    </Box>
                    <div className="toggle-button-group-container">
                        <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                        ref={buttonRef}
                        >
                            <ToggleButton className='toggle-button' value="adult" onClick={clickButton}>Взрослый</ToggleButton>
                            <ToggleButton className='toggle-button' value="kid" onClick={clickButton}>Детский</ToggleButton>
                            <ToggleButton className='toggle-button' value="old" onClick={clickButton}>Пенсионный</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                  </div>
                  <div className="output-container">
                      <div className="output">
                          <div className="cost">
                              <div className="cost-output-value">{costOutputValue && <div>{costOutputValue}</div>}</div>
                              <div className="output-text">Стоимость</div>
                          </div>
                          <div className="discount">
                          <div className="discount-output-value">{discountOutputValue && <div>{discountOutputValue}</div>}</div>
                              <div className="output-text">Ваша выгода</div>
                          </div>
                      </div>
                      <div className="output-button-container">
                          <button className="output-button" onClick={() => setShowModal(true)}>Забронировать</button>
                      </div>
                      </div>
                    </div>
                    {showModal && (
                        <div className="modal">
                          <div className="modal-content">
                            <span className="close" onClick={handleClose}>
                              &times;
                            </span>
                            <h2 className="modal-header">Оставьте свои данные и мы с вами свяжемся</h2>
                            <form onSubmit={handleSubmit} className="form">
                              <div className="user-input-container">
                                <label htmlFor="name" className="user-input-text">Имя:</label>
                                <input
                                  type="text"
                                  id="name"
                                  name="name"
                                  className="user-input"
                                  onChange={handleInput}
                                />
                              </div>
                              <div className="user-input-container">
                                <label htmlFor="tel" className="user-input-text">Номер телефона:</label>
                                <input
                                  type="tel"
                                  id="tel"
                                  name="tel"
                                  className="user-input"
                                  onChange={handleInput}
                                />
                              </div>
                              <div className="checkbox-container"> 
                                <input
                                 type="checkbox" 
                                 name=""
                                 className="checkbox" 
                                 checked /> 
                                <p className="checkbox-p">Я даю своё согласие на обработку персональных данных в соответствии с <a href="https://cloud.mail.ru/public/Qigc/L8a41oZv5" target="_blank" className="checkbox-link">пользовательским соглашением</a></p>
                              </div>
                              <div className="modal-button-container">
                                <button type="submit" className="modal-button">Отправить</button>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                </div>
        )
    }

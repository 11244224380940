import './About.scss';
import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Benefits from "../../components/Benefits/Benefits";
import Advantages from "../../components/Advantages/Advantages";
import Gallery from "../../components/Gallery/Gallery";
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default class About extends Component {
    render() {
        return(
            <div className="about">
                <Header />
                <section className='about-section'>
                <div className='benefits-advantages'>
                    <Benefits />
                    <Advantages />
                </div>
                <Gallery />
                </section>
            </div>
        )
    }
}
import React, { Component } from "react"
import "./Map.scss"

function YandexMaps() {
    let windowWidth = window.screen.width;
    if(windowWidth<801){
        return <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab1e8fd1e597f600a364bfd75fdc08ce441fc1efd89a024bcea6b278724f63bed&amp;source=constructor" width="330" height="240" frameborder="0"></iframe>;
    }
    if (windowWidth >= 1200) {
        return <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab1e8fd1e597f600a364bfd75fdc08ce441fc1efd89a024bcea6b278724f63bed&amp;source=constructor" width="1060" height="400" frameborder="0"></iframe>
     }
    if (801<=windowWidth<1200) {
       return <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ab1e8fd1e597f600a364bfd75fdc08ce441fc1efd89a024bcea6b278724f63bed&amp;source=constructor" width="700" height="320" frameborder="0"></iframe>
    }
    
  }

export default class Map extends Component {
    render() {
        return(
            <div className="map">
                <h2 className='map-header'>Местоположение</h2>
                <div className="yandexmap-container">
                    <YandexMaps />
                </div>
            </div>
        )
    }
}
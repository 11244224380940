import React, { Component } from "react"
import "./Gallery.scss"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gal1 from "./gal_1.png";
import gal2 from "./gal_2.png";
import gal3 from "./gal_3.png";


export default function Gallery() {
    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1
      };
    return(
        <div className="gallery">
            <h2 className='gallery-header'>Галерея</h2>
            <Slider {...settings}>
                <div>
                    <img
                     src={gal1}
                     className="gallery-img"
                     />
                </div>
                <div>
                    <img
                     src={gal2}
                     className="gallery-img"
                     />
                </div>
                <div>
                    <img
                     src={gal3}
                     className="gallery-img"
                     />
                </div>
            </Slider>
        </div>
    )
}
import React, { Component, useState } from "react"
import "./WelcomeScreen.scss"
import mobilearrow from  "./mobile_arrow.png"
import axios from "axios"

export default function WelcomeScreen(){
  
 //функции модального окна

 const [post, setPost] = useState({
  name: '',
  tel: ''
})
const [showModal, setShowModal] = useState(false);
const handleInput = (event) => {
  setPost({...post, [event.target.name]: event.target.value})
}


function handleSubmit(event) {
  event.preventDefault();
  console.log(post)
  axios({
    method: 'post',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    url: 'https://academsalt.ru/form.php',
    data: { post }
})
.then(function(response) {

    console.log(response);

})
.catch(function (error) {
    console.log(error);
});
  setShowModal(false);
  //setName("");
  //setPhoneNumber("");
}

function handleClose() {
  setShowModal(false);
  //setName("");
  //setPhoneNumber("");
}
return(
            <div className="welcomeScreen">
                <h1 className='main-h1'>Соляные пещеры в центре Новосибирска</h1>
                <div className='toformbutton' onClick={() => setShowModal(true)}>
                    <h3 className='toformbuuton-text'>ЗАПИСАТЬСЯ</h3>
                    <img
                    src={mobilearrow}
                    className="toformbutton-img"
                    alt="Logo"
                    />
                </div>
                {showModal && (
                 <div className="modal">
                   <div className="modal-content">
                     <span className="close" onClick={handleClose}>
                       &times;
                     </span>
                     <h2 className="modal-header">Оставьте свои данные и мы с вами свяжемся</h2>
                     <form onSubmit={handleSubmit} method="post" className="form" action="https://academsalt.ru/form.php">
                       <div className="user-input-container">
                         <label htmlFor="name" className="user-input-text">Имя:</label>
                         <input
                           type="text"
                           id="name"
                           name="name"
                           className="user-input"
                           onChange={handleInput}
                         />
                       </div>
                       <div className="user-input-container">
                         <label htmlFor="tel" className="user-input-text">Номер телефона:</label>
                         <input
                           type="tel"
                           id="tel"
                           name="tel"
                           className="user-input"
                           onChange={handleInput}
                         />
                       </div>
                       <div className="checkbox-container"> 
                         <input
                          type="checkbox" 
                          name=""
                          className="checkbox" 
                          checked /> 
                         <p className="checkbox-p">Я даю своё согласие на обработку персональных данных в соответствии с <a href="https://cloud.mail.ru/public/Qigc/L8a41oZv5" target="_blank" className="checkbox-link">пользовательским соглашением</a></p>
                       </div>
                       <div className="modal-button-container">
                         <button type="submit" className="modal-button">Отправить</button>
                       </div>
                     </form>
                   </div>
                 </div>
                      )}
            </div>
        )
    }
import './Pricing.scss';
import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Subscriptions from "../../components/Subscriptions/Subscriptions";


export default class Pricing extends Component {
    render() {
        return(
            <div className="pricing">
                <Header />
                <Subscriptions />
            </div>
        )
    }
}
import React, { Component } from "react"
import "./Connect.scss"
import call from './call.svg';
import whatsapp from './whatsapp.svg';
import email from './email.svg';
import vk from './vk.svg';

export default class Connect extends Component {
    render() {
        return(
            <div className="connect">
                <h2 className='connect-header'>Связаться с нами</h2>
                <div className='connect-container'>
                    <div className="connect-item">
                        <img src={call} className="connect-item-call"/>
                        <a href="" className="connect-item-text">+7-913-707-71-82</a>
                    </div>
                    <div className="connect-item">
                        <img src={whatsapp} className="connect-item-whatsapp"/>
                        <a href="" className="connect-item-text">+7-913-707-71-82</a>
                    </div>
                    <div className="connect-item">
                        <img src={email} className="connect-item-email"/>
                        <a href="" className="connect-item-text">academsalt@bk.ru</a>
                    </div>
                    <div className="connect-item">
                        <img src={vk} className="connect-item-vk"/>
                        <a href="https://vk.com/academsaltnsk" className="connect-item-text">@academsaltnsk</a>
                    </div>
                </div>
            </div>
        )
    }
}
import './index.scss';
import React, { Component } from "react";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import Pricing from "./pages/Pricing/Pricing";
import Contacts from "./pages/Contacts/Contacts";
import { Route, Routes, Link } from 'react-router-dom';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import style from './HomeHeader.module.scss';
import logo from './academsaltlogo.png';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const HomeHeader = () => {
  const [nav, setNav] = useState(false);
  return (
    <header className={style.header}>
      <div className='container'>
        <div className={style.box}>
            <Link to='/'><img src={logo} className={style.logo} alt='/' /></Link>   
          <ul
            className={
              nav ? [style.menu, style.active].join(' ') : [style.menu]
            }
          >
            <li><Link to="/pricing">Стоимость</Link></li>
            <li><Link to="/contacts">Контакты</Link></li>
            <li><Link to="/about">О нас</Link></li>
          </ul>
          <div onClick={() => setNav(!nav)} className={style.mobile_btn}>
            {nav ? <AiOutlineClose size={36} /> : <AiOutlineMenu size={36} />}
          </div>
        </div>
      </div>
    </header>
  );
};

/*function CustomLink ({ href, children, ...props }) {
    const path = window.location.pathname
    return (
        <li>
            <a href={href} {...props}>
                {children}
            </a>
        </li>
    )
}
*/
export default HomeHeader;

// className={path === href ? "active-a" : ""}
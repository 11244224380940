import './Contacts.scss';
import React, { Component } from "react";
import Header from "../../components/Header/Header";
import Connect from "../../components/Connect/Connect";
import Map from "../../components/Map/Map";

export default class Contacts extends Component {
    render() {
        return(
            <div className="contacts">
                <Header />
                <section className='contacts-section'>
                    <Map />
                    <Connect />
                </section>
            </div>
        )
    }
}